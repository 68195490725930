<template>
  <publicExport :exportTypeList="exportTypeList"></publicExport>
</template>
<script>
import { alias2f085dd487c1465383484787b29fef2c } from '@/customFolder/customVueAlias.js';

import publicExport from "@/components/common/publicExport";
import {setConfigCharacters} from "@/utils/common";

export default {
  name: "exportTask",
  data() {
    return {
      exportTypeList: [
        {value: 'productExport', name: alias2f085dd487c1465383484787b29fef2c.t('key1000670')},
        {value: 'inventoryExport', name: alias2f085dd487c1465383484787b29fef2c.t('key1000698')},
        {value: 'inventoryLogExport', name: alias2f085dd487c1465383484787b29fef2c.t('key1005498')},
        {value: 'packageExport', name: alias2f085dd487c1465383484787b29fef2c.t('key1002455')},
        {value: 'ymsInventoryExport', name: `${setConfigCharacters}${alias2f085dd487c1465383484787b29fef2c.t('key1005497')}`},
        {value: 'supplierBillExport', name: alias2f085dd487c1465383484787b29fef2c.t('key1005499')},
        {value: 'wmsPickupOrderExport', name: alias2f085dd487c1465383484787b29fef2c.t('key1005500')},
        {value: 'packageInfoOrderExport', name: alias2f085dd487c1465383484787b29fef2c.t('key1001323')},
        {value: 'packageInfoStockExport', name: alias2f085dd487c1465383484787b29fef2c.t('key1001221')},
      ]
    };
  },
  components: {
    publicExport
  }
};
</script>

<style scoped>

</style>
